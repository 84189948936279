<template>
  <div>
    <b-form @submit.prevent.stop="salvar">
      <div class="row">
        <div class="col">
          <b-form-group>
            <label>Nome</label>
            <b-form-input v-model="bandeira.nome"> </b-form-input>
          </b-form-group>
        </div>

        <div
          class="col-3 d-flex justify-content-around align-self-center form-control mt-3"
        >
          <b-form-checkbox :value="1" :unchecked-value="0" v-model="bandeira.credito" switch>Crédito</b-form-checkbox>
          <b-form-checkbox :value="1" :unchecked-value="0" v-model="bandeira.debito" switch>Débito</b-form-checkbox>
        </div>
      </div>
      <!-- <div class="row justify-content-center">
        <div class="col-12">
          <b-form-group>
            <estabelecimentos-select
              :customReduce="(value) => value"
              icon
              @input="addEstabelecimento($event)"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <b-table
            striped
            hover
            responsive="sm"
            stacked="sm"
            small
            outlined
            class="shadow"
            :fields="campos"
            :items="estabelecimentosFiltrados"
            :per-page="paginacao.registros_por_pagina"
            :current-page="paginacao.pagina_atual"
            show-empty
          >
            <template #empty>
              <div class="text-center">
                Nenhuma estabelecimento por enquanto.
                <i class="fas fa-store"></i>
              </div>
            </template>
            <template #cell(actions)="{item}">
              <b-btn
                size="sm"
                variant="transparent"
                @click="removeEstabelecimentos(item.id)"
              >
                <i class="fas fa-times"></i>
              </b-btn>
            </template>
          </b-table>
          <div class="row d-flex align-items-baseline">
            <div class="col-6">
              <b-pagination
                v-model="paginacao.pagina_atual"
                :per-page="paginacao.registros_por_pagina"
                :total-rows="bandeira.estabelecimentos.length"
              >
              </b-pagination>
            </div>
          </div>
        </div>
      </div> -->
      <div class="bottom-actions pt-3 border-top">
        <b-btn variant="primary" type="submit" class="mr-3">
          <i class="fas fa-save"></i> Salvar
        </b-btn>
        <b-btn variant="secondary" @click="$emit('close')">
          <i class="fas fa-ban"></i> Cancelar
        </b-btn>
      </div>
    </b-form>
  </div>
</template>

<script>
import CartaoBandeirasLib from "../../libs/CartaoBandeirasLib";
//import EstabelecimentosSelect from "../common/EstabelecimentosSelect.vue";
// import SelectEntidade from "../common/SelectEntidade.vue";

export default {
  components: {
   // EstabelecimentosSelect,
    // SelectEntidade,
  },
  props: {
    id: [String, Number],
  },
  data() {
    return {
      bandeira: {
        nome: "",
        credito: 0,
        debito: 0,
        //estabelecimentos: [],
      },
      campos: [
        {
          key: "numero",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
        },
      ],
      paginacao: {
        registros_por_pagina: 5,
        pagina_atual: 1,
      },
    };
  },
  methods: {
    validar() {
      let validacao = { validou: true, msg: [] };
      if (this.bandeira.nome == "") {
        validacao.validou = false;
        validacao.msg.push("informe o nome da bandeira!");
      }
      if (!this.bandeira.debito && !this.bandeira.credito  ) {
        validacao.validou = false;
        validacao.msg.push("Informe se o cartão é <b>crédito</b> e/ou <b>débito</b> !");
      }

      // if (this.bandeira.estabelecimentos.length == 0) {
      //   validacao.validou = false;
      //   validacao.msg.push("informe ao menos um estabelecimento para a bandeira!");
      // }

      // if (!this.bandeira.fornecedor) {
      //   validacao.validou = false;
      //   validacao.msg.push("informe o fornecedor da bandeira!");
      // }

      // if (this.solicitacao.respostas.length == 0) {
      //   validacao.validou = false;
      //   validacao.msg.push(
      //     "O modelo selecionado está com algum problema, por favor, verifique se ele foi cadastrado corretamente!"
      //   );
      // }

      return validacao;
    },
    async salvar() {
      let valida = this.validar();
      // console.log("validou?", valida);
      // console.log("solicitacao:", this.solicitacao);
      if (valida.validou) {
        try {
          let result = await CartaoBandeirasLib.store(this.bandeira);
          if (result.status == 201 || result.status == 200) {
            this.showToast("success", "Estabelecimento salvo com sucesso!");
            this.$emit("salvou");
          }
        } catch (error) {
          console.log(error);
          this.showToast("error", error.error.message);
        }
      } else {
        this.$swal.fire({
          title: "Erro",
          icon: "error",
          html: `<b>Os detalhes a seguir precisam de atenção</b><hr/><span class='text-left'>${valida.msg
           
            .reduce((it, vl) => {
              it += vl + " <hr />";
              return it;
            }, "")} `,
        });
        // this.$swal("Erro", ...valida.msg, "error");
      }
    },
    addEstabelecimento(estabelecimento) {
      if (
        this.bandeira.estabelecimentos &&
        this.bandeira.estabelecimentos.some((e) => e.id == estabelecimento.id)
      ) {
        this.showToast(
          "error",
          "Esse estabelecimento já foi adicionado à bandeira."
        );
      }
      if (estabelecimento) {
        this.bandeira.estabelecimentos.push({
          ...estabelecimento,
          _rowStatus: "added",
        });
      }
    },
    removeEstabelecimentos(id) {
      if (id) {
        const i = this.bandeira.estabelecimentos.findIndex((e) => e.id == id);
        const estabelecimento = this.bandeira.estabelecimentos[i];

        if (estabelecimento) {
          if (
            estabelecimento._rowStatus &&
            estabelecimento._rowStatus == "added"
          ) {
            this.bandeira.estabelecimentos.splice(i, 1);
          } else {
            this.$set(estabelecimento, "_rowStatus", "deleted");
          }
        }
      }
    },
  },
  async mounted() {
    if (this.id) {
      this.bandeira = (await CartaoBandeirasLib.get(this.id))[0];
    }
  },
  computed: {
    estabelecimentosFiltrados() {
      return this.bandeira.estabelecimentos.filter((e) =>
        e._rowStatus ? (e._rowStatus == "deleted" ? false : true) : true
      );
    },
  },
};
</script>

<style></style>
