<template>
  <div>
    <b-table
      :fields="campos"
      :items="bandeiras"
      responsive="sm"
      :filter="filtro"
      :per-page="paginacao.registros_por_pagina"
      :current-page="paginacao.pagina_atual"
      striped
      :busy="loading"
      show-empty
    >
      <template #empty>
        <img
          src="../../../src/assets/img/icones/menu/card_security_40px_dark.png"
        />
        Nenhuma bandeira cadastrado, clique em
        <b-btn @click="$emit('novo')" variant="primary" size="sm" class="mx-2">
          <i class="fas fa-plus"></i> Bandeira</b-btn
        >
        para cadastrar.
      </template>
       <template #table-busy class="text-center">
        <b-spinner  />
      </template>
      <!-- <template #cell(status)="row">
        <b-form-checkbox
          v-model="row.item.status"
          :unchecked-value="0"
          :value="1"
          switch
        ></b-form-checkbox>
      </template> -->
      <template #cell(actions)="row">
        <b-btn
          variant="warning"
          size="sm"
          class="mr-3"
          @click="editar(row.item.id)"
        >
          <i class="fas fa-edit"></i> Editar</b-btn
        >
        <b-btn variant="danger" size="sm" @click="excluir(row.item.id)">
          <i class="fas fa-trash"></i> Excluir</b-btn
        >
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      campos: [
        { key: "id", label: "Código", sortable: true },
        { key: "nome", label: "Nome", sortable: true },

        { key: "actions", label: "", class: "text-right" },
      ],
      paginacao: {
        registros_por_pagina: 10,
        pagina_atual: 1,
      },
    
    };
  },
  props: {
    bandeiras: [Array],
    loading: { type: Boolean, default: false },
  },
  methods: {
    editar(id) {
      this.$emit("editar", id);
    },
    excluir(id) {
      this.$emit("excluir", id);
    },
  },
  mounted() {
    this.carregar();
  },
  watch: {

  }
};
</script>

<style></style>
